import React from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { Grid, Cell, colors } from '../theme';
import ButtonLink from './Buttons';
import FormsConsualtant from './Forms-consualtant';
import { IconLoader, ICONS } from './IconLoader';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
`;

const DetailsWrapper = styled.div`
  padding: 10rem 3rem;
`;

const DetailsIcon = styled.img`
  max-width: 100%;
  height: auto;
`;

const AfterH = styled.h2`
  color: black;
  font-weight: 900;
  font-size: 2.5rem;
  width: 100%;
  display: flex;
  white-space: nowrap;
  padding: 2rem 0;
  ::after {
    border-top: 1px solid;
    content: '';
    display: block;
    position: relative;
    top: 0.7em;
    width: 100%;
    right: 1.5%;
  }
`;
const DetailsText = styled.div`
  text-align: justify;
  margin: 2rem 0;
  color: ${colors.gray.pTagsColor};
`;
const DetailsTextBold = styled.div`
  text-align: justify;
  margin: 2rem 0;
  color: ${colors.gray.pTagsColor};
  font-weight: 900;
  font-size: 2rem;
`;

const MainSectionContactUs = () => {
  return (
    <SectionLayout>
      <Container>
        <Grid rowGap={'1.5rem'} rows={1} columns={2}>
          <Cell>
            <FormsConsualtant></FormsConsualtant>
          </Cell>
          <Cell>
            <DetailsWrapper>
              <DetailsIcon src="/images/icon-doctor.png" />
              <DetailsText>
                جهت آسایش و حفظ سلامتی شما بیماران عزیز، سیستم مشاوره آنلاین
                پزشکی و ویزیت آنلاین در این سایت فعال شده است.
              </DetailsText>
              <AfterH>مشاوره آنلاین پزشکی</AfterH>

              <DetailsText>
                جهت دریافت پاسخ به سوالات جزیی بیماران میتوانند از طریق شبکه
                واتساپ دکتر محمود سامعی ۰۹۳۵۹۳۵۹۹۴۷ استفاده نمایند.
              </DetailsText>

              <ButtonLink
                link={'https://wa.me/989122207244'}
                title={'پرسش و پاسخ از طریق واتساپ'}
                bgColor={colors.green.greenButton}
                bgColorIcon={colors.green.greenButtonIcon}
              >
                <IconLoader name={ICONS.whatsapp} />
              </ButtonLink>
              <DetailsText>
                پاسخ سوالات از طریق شبکه واتساپ ظرف ۲۴ الی ۴۸ ساعت
              </DetailsText>

              <AfterH>ویزیت آنلاین</AfterH>
              <DetailsText>
                جهت ویزیت آنلاین به صورت تصویری و یا صوتی؛ شرح دقیق بیماری و عکس
                کاملا خوانا از مدارک پزشکی خود را به شبکه واتساپ (و یا تلگرام)
                دکتر محمود سامعی ۰۹۳۵۹۳۵۹۹۴۷ ارسال نمایید و یا فرم مربوطه را
                تکمیل نمایید.
              </DetailsText>
              <DetailsText>
                بسته به تعداد نوبت های آنلاین، ۲۴ تا ۴۸ ساعت پس از تکمیل فرم زیر
                و پرداخت ویزیت، دکتر محمود سامعی با شما تماس خواهد گرفت.
              </DetailsText>

              <DetailsText>
                لطفا پس از واریز مبلغ ویزیت به شماره کارت زیر؛ عکس فیش واریزی را
                به شماره واتساپ ۰۹۳۵۹۳۵۹۹۴۷ ارسال نمایید و ساعت تماس را دریافت
                نمایید.
              </DetailsText>
              <DetailsTextBold>مبلغ ویزیت: ۷۰,۰۰۰ تومان</DetailsTextBold>
              <DetailsTextBold>
                شماره کارت و صاحب حساب: محمود سامعی
              </DetailsTextBold>
              <DetailsTextBold>8020 - 1137 - 8510 - 5057</DetailsTextBold>
              <DetailsTextBold>شعبه: بانک ایران زمین</DetailsTextBold>

              {/* <ButtonLink
                link={'/form-page'}
                title={'فرم درخواست ویزیت'}
                bgColor={colors.blue.captionBlue}
                bgColorIcon={colors.blue.buttonIconBlue}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
              </ButtonLink> */}
            </DetailsWrapper>
          </Cell>
        </Grid>
      </Container>
    </SectionLayout>
  );
};

export default MainSectionContactUs;
