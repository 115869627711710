import React from 'react';
import Layout from '../components/Layout';
import HeadPanel from '../components/HeadPanel';
import SeperatorTopFull from '../components/Seperator-top-full';
import MainSectionContactUs from '../components/Main-section-contactUs';
import TransportSection from '../components/Section-contact-transport';
import BgImageSection from '../components/Bg-image-section';
import { useFiles } from '../hooks/files';

const HomePage = () => {
  const { doctorBlueJpeg, facilitiesAbout } = useFiles();
  return (
    <Layout>
      <div>
        <HeadPanel
          img={facilitiesAbout}
          title={'تماس با دکتر'}
          subtitle={'درخواست مشاوره پزشکی و ویزیت آنلاین'}
        ></HeadPanel>
        <SeperatorTopFull />
        <MainSectionContactUs />
        <TransportSection />
        <BgImageSection img={doctorBlueJpeg} />
      </div>
    </Layout>
  );
};

export default HomePage;
